.editor-wrapper {
    background-color: white;
    color: black;
}

.editor {
    background-color: white;
    color: black;
    height: 500px;
}

.editor-toolbar {
  color: black;
}
